.
<template>
  <nav
    class="lg:sticky top-0 z-20 bg-white"
    data-testid="checkout-layout-header"
  >
    <div class="bg-primary rounded-b-xl sm:rounded-none">
      <div class="max-w-screen-xl mx-auto p-4">
        <NuxtLinkWithLocale
          to="/"
          data-testid="checkout-layout-header-home-link"
        >
          <NuxtImg
            class="inline"
            src="/images/logos/toolstation-logo-halo.svg"
            data-testid="checkout-layout-header-toolstation-logo"
            alt="logo"
            height="44"
            width="180"
            loading="lazy"
          />
        </NuxtLinkWithLocale>
      </div>
    </div>

  </nav>
</template>
<script setup lang="ts">
import { useRandomUUID } from "../composables/useRandomUUID";
import { ref } from "vue";

const random_id = ref("");

type Props = {
  dataTestid?: string;
};

const props = withDefaults(defineProps<Props>(), {});

onMounted(() => {
  random_id.value = useRandomUUID();
});
</script>
